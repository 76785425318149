var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "handleAnswer", () => {
      if (this.answerController.questionController) {
        this.answerController.questionController.triggerModal();
      }
      this.answerController.chainController.nextStep();
    });
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
  initialize() {
  }
  connect() {
  }
}
__publicField(component_controller_default, "targets", ["form"]);
