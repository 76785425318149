var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class params_to_links_controller_default extends ApplicationController {
  initialize() {
    console.log("params to links init", this.tokenValue);
  }
  connect() {
    console.log("params to links connect", this.tokenValue);
  }
}
__publicField(params_to_links_controller_default, "values", {
  token: String
});
