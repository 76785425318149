import ApplicationController from "@/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
    console.log("offer_wall row initialize");
  }
  connect() {
    console.log("offer_wall row connect");
  }
  log() {
    console.log("offer_wall row here");
  }
}
