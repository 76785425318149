import ApplicationController from "@/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
    console.log("[FORM BUILDER] initialize");
  }
  connect() {
  }
  submit(event) {
    event.preventDefault();
    const isValid = this.validatePresence();
  }
  validatePresence() {
    let isValid = true;
    let requiredFieldSelectors = "select:required textarea:required, input:required";
    let requiredFields = this.element.querySelectorAll(requiredFieldSelectors);
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();
        isValid = false;
      }
    });
    return isValid;
  }
}
