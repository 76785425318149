var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "answerStore", {});
  }
  initialize() {
    this.updateCurrentAnswer();
  }
  connect() {
  }
  handleAnswer(event) {
    event.preventDefault();
    this.answerController.getAnswerData(
      this.currentAnswerElement || this.answerStore
    );
    this.answerController.handleAnswer();
  }
  handleRangeChange() {
    this.updateCurrentAnswer();
  }
  updateCurrentAnswer() {
    var _a, _b;
    if (this.hasCurrentAnswerTarget) {
      this.currentAnswerTarget.textContent = (_a = this.currentAnswerElement) == null ? void 0 : _a.textContent;
    } else {
      this.answerStore = {
        dataset: {
          text: (_b = this.rangeInputTarget) == null ? void 0 : _b.value
        }
      };
    }
  }
  get currentAnswerElement() {
    return this.rangeAnswerTargets[this.rangeInputTarget.value];
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "chain--answer--component"
    );
  }
}
__publicField(component_controller_default, "targets", ["rangeAnswer", "rangeInput", "currentAnswer"]);
