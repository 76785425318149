var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "AC_THRESHOLD", 3);
    __publicField(this, "handleAddressChange", (e) => {
      const target = e.target;
      const val = target.value;
      const container = this.resultsTarget;
      if (val.length > this.AC_THRESHOLD) {
        if (!this.sessionToken)
          this.refreshSessionToken();
        this.service.getPlacePredictions(
          { input: val, sessionToken: this.sessionToken, componentRestrictions: { country: ["us"] } },
          (items, status) => this.displaySuggestions(items, status, container)
        );
      } else {
        const acList = this.resultsTarget;
        acList.classList.add("hidden");
      }
    });
    __publicField(this, "displaySuggestions", (predictions, status, container) => {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        console.error("GOOGLE PLACES SERVICE", status);
        return;
      }
      const autocompleteList = this.resultsTarget;
      autocompleteList.innerHTML = "";
      autocompleteList.classList.remove("hidden");
      console.log("PREDICTION", predictions);
      predictions.map((prediction) => {
        const li = document.createElement("li");
        li.classList.add(
          "cursor-pointer",
          "px-4",
          "py-1",
          "text-sm",
          "text-left",
          "text-gray-base",
          "hover:bg-gray-light"
        );
        li.onclick = this.handleItemClick;
        li.dataset.id = prediction.place_id;
        li.appendChild(document.createTextNode(prediction.description));
        autocompleteList.appendChild(li);
      });
    });
    __publicField(this, "handleItemClick", (e) => __async(this, null, function* () {
      const target = e.target;
      const input = this.inputTarget;
      const autocompleteList = this.resultsTarget;
      input.value = target.innerText;
      autocompleteList.classList.add("hidden");
      yield this.getStateByPlaceId(target.dataset.id);
      this.refreshSessionToken();
    }));
    __publicField(this, "getStateByPlaceId", (placeId) => __async(this, null, function* () {
      const saveToUserStore = (key, text) => __async(this, null, function* () {
        this.userStoreModule = yield ResultsStoreModule("savedUser");
        yield this.userStoreModule.init();
        yield this.userStoreModule.saveAnswer(key, text);
      });
      const request = {
        placeId,
        fields: ["address_component"]
      };
      const saveStateToUserStore = (place, _status) => __async(this, null, function* () {
        console.log(":::place", place);
        const stateData = place["address_components"].find((i) => i.types.includes("administrative_area_level_1"));
        const cityData = place["address_components"].find((i) => i.types.includes("locality") || i.types.includes("administrative_area_level_1"));
        const zipData = place["address_components"].find((i) => i.types.includes("postal_code"));
        const shortState = stateData["short_name"];
        const city = cityData["long_name"];
        const zip = zipData["long_name"];
        saveToUserStore("state", shortState);
        saveToUserStore("city", city);
        saveToUserStore("zip", zip);
        return stateData;
      });
      this.detailsService.getDetails(request, saveStateToUserStore);
    }));
    __publicField(this, "refreshSessionToken", () => {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    });
  }
  initialize() {
    this.service = new google.maps.places.AutocompleteService();
    this.detailsService = new google.maps.places.PlacesService(this.mapTarget);
    this.sessionToken = false;
  }
  connect() {
  }
}
__publicField(component_controller_default, "targets", ["input", "results", "map"]);
