var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const ResultsStoreModule = (storeKey = "questions_results") => __async(void 0, null, function* () {
  const STORE_KEY = storeKey;
  let results = localStorage.getItem(STORE_KEY);
  const init = (afterInitCb = () => {
  }) => __async(void 0, null, function* () {
    try {
      results = yield getStore(STORE_KEY);
      if (results === null)
        yield setResults();
      return afterInitCb();
    } catch (e) {
      console.log("[init] error", e);
      if (results === null)
        yield setResults();
      return afterInitCb();
    }
  });
  const saveAnswer = (questionId, answer) => {
    if (questionId) {
      const updatedResults = __spreadProps(__spreadValues({}, results), { [questionId]: answer });
      return setResults(updatedResults);
    }
  };
  const setResults = (updatedResults = {}) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(STORE_KEY, JSON.stringify(updatedResults));
        results = JSON.parse(localStorage.getItem(STORE_KEY));
        window.dispatchEvent(new Event("storage"));
        resolve(results);
      } catch (error) {
        console.log("[setResults] error", error);
        reject(error);
      }
    });
  };
  const getStore = (storeName) => {
    return new Promise((resolve, reject) => {
      try {
        let data = localStorage.getItem(storeName);
        data ? resolve(JSON.parse(data)) : resolve({});
      } catch (e) {
        console.log("[getStore] error", e);
        reject(e.message);
      }
    });
  };
  const getResultByQuestionId = (questionId) => {
    if (questionId)
      return results[questionId];
  };
  return {
    init,
    saveAnswer,
    getResultByQuestionId,
    getStore,
    setResults,
    STORE_KEY
  };
});
