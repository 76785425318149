var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "handleDayChange", (e) => {
      this.daySelectTarget.setCustomValidity("");
      this.day = e.target.value;
    });
    __publicField(this, "handleMonthChange", (e) => {
      this.month = e.target.value;
    });
    __publicField(this, "handleYearChange", (e) => {
      this.year = e.target.value;
    });
  }
  initialize() {
    console.log("Answer::DateSelectController[index]", this.element);
    this.day;
    this.month;
    this.year;
  }
  connect() {
    console.log("Answer::DateSelectController[connect]");
  }
  handleSubmit(e) {
    e.preventDefault();
    let isValidDate = false;
    let daysOnDate = 0;
    const getDays = (year, month) => {
      return new Date(year, month, 0).getDate();
    };
    const handleAnswer = (target) => {
      this.answerController.getAnswerData(target);
      this.answerController.handleAnswer();
    };
    if (this.monthSelectTarget.reportValidity() && this.daySelectTarget.reportValidity() && this.yearSelectTarget.reportValidity()) {
      daysOnDate = getDays(this.year, this.month);
      isValidDate = this.day <= daysOnDate;
    } else {
      return false;
    }
    console.log("SUBMIT:", this.month + "-" + this.day + "-" + this.year);
    if (isValidDate) {
      e.target.dataset.text = `${this.month}-${this.day}-${this.year}`;
      handleAnswer(e.target);
    } else {
      this.daySelectTarget.setCustomValidity("There is only " + daysOnDate + " days in this month");
      this.daySelectTarget.reportValidity();
    }
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "targets", ["daySelect", "monthSelect", "yearSelect", "submitButton"]);
