var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class EventTrackerModule {
  constructor(pixel) {
    __publicField(this, "pixel");
    this.pixel = pixel;
  }
  track(name, params) {
    var _a;
    const trustedFormToken = (_a = document.getElementsByName("xxTrustedFormToken")[0]) == null ? void 0 : _a.value;
    const leadidToken = typeof LeadiD != "undefined" && LeadiD.token && typeof LeadiD.token != "undefined" && LeadiD.token;
    let referrer = "";
    let referrerParams = {};
    const currentParams = window.location.search;
    if (document.body.dataset.hiddenReferrer) {
      referrer = new URL(document.body.dataset.hiddenReferrer);
      referrerParams = Object.fromEntries(new URLSearchParams(referrer.search));
    }
    const additionalParams = {
      userAgent: navigator.userAgent,
      urlParams: currentParams,
      trusted_form_token: trustedFormToken,
      leadid_token: leadidToken,
      ls_questions_results: window.localStorage.getItem("questions_results"),
      ls_saved_user: window.localStorage.getItem("savedUser"),
      hidden_referrer: referrer,
      hidden_referer_params: referrerParams
    };
    this.pixel.track(name, { params, additionalParams });
  }
}
