var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    const trialId = this.hasTrialIdValue && this.trialIdValue.length > 0 ? this.trialIdValue : "NCT05085366";
    console.log("@@@@#!#!#!#", trialId);
    this.zipService = new ZipService();
    this.trialscopeService = new TrialscopeService(
      trialId,
      "48e68df0989ad4cfa0ede1aa84ee0559"
    );
    this.publicErrorMessage = "sorry not available for now";
  }
  connect() {
    this.performWallLoading();
    window.addEventListener("storage", (e) => {
      console.log("storage updated", e);
      this.performWallLoading();
    });
  }
  log() {
    console.log("trialscopeWall log");
  }
  goToRedirectUrl(e) {
    console.log("goToRedirectUrl url", this.redirectUrlValue);
    if (this.redirectUrlValue) {
      this.linkModule.performRedirect(this.redirectUrlValue);
    } else {
      if (this.errorMessageTarget)
        this.errorMessageTarget.innerHTML = `<div>${this.publicErrorMessage}</div>`;
    }
  }
  performWallLoading() {
    const zip = this.getZipFromLocalStorage();
    this.clearErrors();
    if (!zip)
      this.handleNoZip();
    if (zip)
      this.renderWall(zip);
  }
  getZipFromLocalStorage() {
    this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}");
    this.questionResults = JSON.parse(
      localStorage.getItem("questions_results") || "{}"
    );
    return this.userData["zip"] || this.questionResults["zip"];
  }
  clearErrors() {
    if (this.errorMessageTarget)
      this.errorMessageTarget.innerHTML = "";
  }
  handleNoZip() {
    if (this.errorMessageTarget)
      this.errorMessageTarget.innerHTML = `<div data-error="No ZIP">${this.publicErrorMessage}</div>`;
  }
  renderWall(zip) {
    return __async(this, null, function* () {
      var _a;
      const {
        lat,
        long,
        error_message: errorMessage
      } = yield this.zipService.toLatLong(zip);
      if (errorMessage) {
        this.errorMessageTarget.innerHTML = `<div data-error="${errorMessage}">${this.publicErrorMessage}</div>`;
        if (this.resultTarget) {
          this.resultTarget.innerHTML = "";
        }
      }
      if (!errorMessage) {
        const wall = yield (_a = this.trialscopeService) == null ? void 0 : _a.loadWall(lat, long);
        if (wall["error_message"]) {
          this.errorMessageTarget.innerHTML = `<div data-error="${errorMessage}">${this.publicErrorMessage}</div>`;
          if (this.resultTarget) {
            this.resultTarget.innerHTML = "";
          }
        } else {
          const offerListResponse = yield fetch("/uikit/offer_list_markup", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-CSRF-Token": getMetaValue("csrf-token")
            },
            body: JSON.stringify({
              offers: wall["result"],
              redirect_url: `${this.redirectUrlValue}`,
              trial_id: `${this.trialIdValue}`,
              wall_source: "trialscope"
            })
          });
          const offerList = yield offerListResponse.json();
          if (this.resultTarget) {
            this.resultTarget.innerHTML = offerList["result"];
          }
        }
      }
      console.log("error_message", errorMessage);
    });
  }
}
__publicField(component_controller_default, "values", {
  trialId: String,
  redirectUrl: String
});
__publicField(component_controller_default, "targets", ["result", "errorMessage", "clickableWrapper"]);
function getMetaValue(name) {
  var _a;
  const element = (_a = document == null ? void 0 : document.head) == null ? void 0 : _a.querySelector(`meta[name="${name}"]`);
  return element == null ? void 0 : element.getAttribute("content");
}
class ZipService {
  constructor() {
    __publicField(this, "API_URL", "https://maps.googleapis.com/maps/api/geocode/json");
    __publicField(this, "API_KEY", "AIzaSyC8RitsVHn7rSDAoQxr-oVtV-EocnETXL4");
  }
  toLatLong(zip) {
    return __async(this, null, function* () {
      try {
        if (!zip)
          return { error_message: "no zip provided" };
        const response = yield fetch(
          `${this.API_URL}?address=${zip}&key=${this.API_KEY}`
        );
        const result = yield response.json();
        if (result.status === "ZERO_RESULTS") {
          return { error_message: result["status"] };
        }
        if (result.status === "OK") {
          if (result["results"] && result["results"].length > 0) {
            const location = result["results"][0]["geometry"] && result["results"][0]["geometry"]["location"];
            if (location)
              return { lat: location["lat"], long: location["lng"] };
          } else {
            return { error_message: result["status"] };
          }
        }
        return { error_message: result["error_message"] || result["error"] };
      } catch (e) {
        return { error_message: e.message };
      }
    });
  }
}
class TrialscopeService {
  constructor(trailId, token) {
    this.wallURL = `https://connect-staging.trialscope.com/api/v1/trials/${trailId}/sites`;
    this.token = token;
  }
  loadWall(lat, long) {
    return __async(this, null, function* () {
      const response = yield fetch(`${this.wallURL}?lat=${lat}&long=${long}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}"`
        }
      });
      const result = yield response.text();
      console.log("12312312312", result);
      console.log("12312312312", result.status);
      if (response.status === 200) {
        return { result: JSON.parse(result) };
      } else {
        return {
          error_message: `Status: ${response.status} - Message: ${result["error"]}`
        };
      }
    });
  }
}
