var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
export default class extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  initialize() {
    console.log("button_component base_component initialize");
  }
  connect() {
    console.log("button_component base_component connect");
  }
  log() {
    console.log("button_component base_component here");
  }
  click() {
    this.trackButtonClick();
  }
  trackButtonClick() {
    this.trackerModule.track("button_component_click", {
      "url_params": window.location.search,
      "page": window.location.pathname,
      "url": window.location.href
    });
  }
}
