var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
import { ResultsStoreModule } from "@/modules/results_store_module";
import { ChainParamConditionModule } from "@/modules/chain_param_conditions_module";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
import SparkMD5 from "spark-md5";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", new LinkModule());
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  get progressbarController() {
    if (this.hasProgressBarTarget) {
      return this.application.getControllerForElementAndIdentifier(this.progressBarTarget, "chain--progress--component");
    }
  }
  initialize() {
    const domain = window.location.host;
    const uniqSeed = this.servicenameValue || this.idValue;
    this.trackingId = SparkMD5.hash(uniqSeed + domain);
    this.addChainTrackingIdToQuestions();
    this.element.classList.add("transition-all", "ease-in", "duration-500", "opacity-0");
    this.applyParamConditions();
  }
  connect() {
    this.element.classList.add("opacity-100");
    this.stepValue = 1;
    this.stepIndexValue = 0;
    this.trackRender();
  }
  addChainTrackingIdToQuestions() {
    this.questionTargets.map((question) => {
      question.dataset.trackingChainId = this.trackingId;
    });
  }
  trackRender() {
    this.trackerModule.track("question_chain_render", {
      tracking_chain_id: this.trackingId,
      servicename: this.servicenameValue,
      steps_count: this.stepsCountValue,
      question_chain_id: this.idValue,
      conditions: this.conditionsValue,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  applyParamConditions() {
    return __async(this, null, function* () {
      if (this.hasConditionsValue) {
        const currentParams = Object.fromEntries(new URLSearchParams(window.location.search));
        const conditionsModule = new ChainParamConditionModule(this.conditionsValue, currentParams, this.questionTargets);
        yield conditionsModule.checkConditions();
        this.connectCurrentQuestion();
      }
    });
  }
  nextStep() {
    if (this.stepValue >= this.questionTargets.length) {
      this.performFinalRedirect();
    } else {
      this.stepValue++;
      this.stepIndexValue++;
    }
  }
  prevStep() {
    if (this.stepValue > 1) {
      this.stepValue--;
      this.stepIndexValue--;
    }
  }
  stepValueChanged() {
    console.log("STEP VALUE CHANGED", this.stepValue);
    const stepsCount = this.questionTargets.length;
    const progressPercent = parseInt(this.stepValue / stepsCount * 100);
    if (this.progressbarController && this.hasProgressBarTarget) {
      this.progressbarController.updateStep(this.stepValue, stepsCount);
      this.progressbarController.updatePercent(progressPercent);
    }
    this.connectCurrentQuestion();
    this.slideToNextQuestion();
  }
  performFinalRedirect() {
    return __async(this, null, function* () {
      const formUrlParams = (obj) => "?" + Object.keys(obj).map((key) => {
        return obj[key] ? key + "=" + obj[key] : "";
      }).join("&");
      if (this.finalUrlValue) {
        this.userStoreModule = yield ResultsStoreModule("savedUser");
        const finalUrlVal = this.finalUrlValue === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.finalUrlValue;
        const userStoreObject = yield this.userStoreModule.getStore("savedUser");
        let finalUrl = this.linkModule.addCurrentParamsToUrl(finalUrlVal);
        finalUrl = new URL(this.linkModule.addParamsToUrl(finalUrl)(formUrlParams(userStoreObject)));
        if (finalUrl.origin == window.location.origin) {
          this.linkModule.performRedirect(finalUrl.toString());
        } else {
          this.linkModule.performRedirect(finalUrl.toString());
        }
      }
    });
  }
  getStepWidth(baseWidth, count, ext) {
    return `${+baseWidth * +count}${ext}`;
  }
  slideToNextQuestion() {
    let stepSize = 100;
    let stepExt = "vw";
    if (this.questionTargets[0]) {
      stepSize = this.questionTargets[0].offsetWidth;
      stepExt = "px";
    }
    this.stepWidth = this.getStepWidth(stepSize, this.stepIndexValue, stepExt);
    this.questionsWrapperTarget.style.transform = `translateX(-${this.stepWidth})`;
    const noScrollToTop = this.hasNoScrollToTopValue && (this.noScrollToTopValue == "true" || this.noScrollToTopValue == "enable");
    if (!noScrollToTop) {
      window.scrollTo(0, 0);
    }
  }
  connectCurrentQuestion() {
    if (this.stepValue === 0)
      return false;
    const questionControllerName = " chain--question--component";
    this.questionTargets.forEach((element, index) => {
      if (this.stepIndexValue === index) {
        if (element.dataset.controller.includes(questionControllerName))
          return;
        element.dataset.controller += questionControllerName;
      } else {
        element.dataset.controller = element.dataset.controller.replace(questionControllerName, "");
      }
    });
  }
}
__publicField(component_controller_default, "values", {
  finalUrl: String,
  id: String,
  step: Number,
  stepIndex: Number,
  stepsCount: Number,
  conditions: Array,
  servicename: String,
  noScrollToTop: String
});
__publicField(component_controller_default, "targets", ["progressBar", "progressCounter", "questionsWrapper", "question"]);
