var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("[Chain::Progress::Component::initialize]", this.element);
  }
  connect() {
    console.log("[Chain::Progress::Component::connect]", true);
  }
  disconnect() {
    console.log("[Chain::Progress::Component::disconnect]", this.element);
  }
  updatePercent(percent) {
    percent = Math.min(percent, 100);
    if (this.hasProgressPercentTarget)
      this.progressPercentTarget.innerText = percent;
    if (this.hasBarStatusTarget)
      this.barStatusTarget.style.width = percent + "%";
  }
  updateStep(current, count) {
    if (this.hasCurrentStepTarget)
      this.currentStepTarget.innerText = current;
    if (this.hasStepsCountTarget)
      this.stepsCountTarget.innerText = count;
  }
  hideProgressBar(hide) {
    if (hide)
      this.element.classList.add("hidden");
    if (!hide)
      this.element.classList.remove("hidden");
  }
}
__publicField(component_controller_default, "targets", ["barStatus", "progressPercent", "currentStep", "stepsCount"]);
