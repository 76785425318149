var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
import SparkMD5 from "spark-md5";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  get progressbarController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(".progress-bar"),
      "chain--progress--component"
    );
  }
  get modalController() {
    return this.application.getControllerForElementAndIdentifier(document.body, "modal");
  }
  get chainController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(".chain"),
      "chain--component"
    );
  }
  get leadFormController() {
    const form = this.element.querySelector('[data-controller="lead-forms--component"]');
    if (form)
      return this.application.getControllerForElementAndIdentifier(form, "lead-forms--component");
    return false;
  }
  initialize() {
    const domain = window.location.host;
    this.activeValue = false;
    const questionTitleEl = this.element.querySelector(".question-title, .header-title");
    this.questionTitle = questionTitleEl ? questionTitleEl.innerText : "";
    this.questionPos = this.element.dataset.position;
    this.trackingId = SparkMD5.hash(this.questionTitle + this.questionPos + domain);
    this.trackingChainId = this.element.dataset.trackingChainId;
    console.log("QUESTION HASH", this.servicenameValue);
  }
  connect() {
    this.activeValue = true;
    this.initProgressbar();
    this.trackRender();
    if (this.leadFormController)
      this.leadFormController.triggerLoader(false);
  }
  disconnect() {
    this.activeValue = false;
    this.activeValueChanged();
  }
  triggerModal() {
    const modalHandle = this.questionModalValue;
    if (!modalHandle)
      return;
    this.modalController.setCurrentModal(modalHandle);
    this.modalController.toggleOpen(false);
  }
  trackingData() {
    return {
      tracking_title: this.questionTitle,
      tracking_chain_id: this.trackingChainId,
      tracking_question_id: this.trackingId,
      tracking_position: this.questionPos
    };
  }
  trackRender() {
    this.trackerModule.track("question_render", __spreadProps(__spreadValues({}, this.trackingData()), {
      question_id: this.questionIdValue,
      question_text: this.questionTextValue,
      question_chain_id: this.chainIdValue,
      page: window.location.pathname,
      url: window.location.href
    }));
  }
  activeValueChanged() {
    const activeClasses = ["transform-none", "opacity-100"];
    const inactiveClasses = ["transform", "translate-y-3", "opacity-0"];
    this.element.classList.remove("hidden");
    if (this.activeValue) {
      this.element.classList.remove(...inactiveClasses);
      this.element.classList.add(...activeClasses);
    } else {
      this.element.classList.remove(...activeClasses);
      this.element.classList.add(...inactiveClasses);
    }
  }
  initProgressbar() {
    if (this.progressbarController)
      this.progressbarController.hideProgressBar(this.hideProgressbarValue);
  }
}
__publicField(component_controller_default, "values", {
  questionId: String,
  questionText: String,
  chainId: String,
  hideProgressbar: Boolean,
  active: Boolean,
  questionModal: String,
  servicename: String
});
