var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class component_controller_default extends ApplicationController {
  initialize() {
    return __async(this, null, function* () {
      this.storeModule = yield ResultsStoreModule("questions_results");
    });
  }
  connect() {
    this.setQuestionResultToInputValue();
    window.addEventListener("storage", (e) => {
      console.log("storage updated", e);
      this.setQuestionResultToInputValue();
    });
  }
  setQuestionResultToInputValue() {
    return __async(this, null, function* () {
      if (!this.storeModule)
        return false;
      yield this.storeModule.init(() => {
        var _a;
        this.questionResult = this.storeModule.getResultByQuestionId(
          this.questionResultIdValue
        );
        console.log("this.questionResult", this.questionResult);
        this.inputTarget && ((_a = this.inputTarget) == null ? void 0 : _a.setAttribute("value", this.questionResult));
      });
    });
  }
}
__publicField(component_controller_default, "targets", ["input"]);
__publicField(component_controller_default, "values", {
  questionResultId: String
});
