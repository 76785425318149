var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "handleQuestionSubmit", (target) => {
      this.answerController.getAnswerData(target);
      this.answerController.handleAnswer();
    });
    __publicField(this, "saveDataToStore", () => __async(this, null, function* () {
      this.resultsStoreModule = yield ResultsStoreModule();
      yield this.resultsStoreModule.init();
      yield this.resultsStoreModule.saveAnswer("carYear", this.yearSelection);
      yield this.resultsStoreModule.saveAnswer("carMake", this.makeSelection);
      yield this.resultsStoreModule.saveAnswer("carModel", this.modelSelection);
    }));
    __publicField(this, "handleYearSelect", (e) => {
      e.preventDefault();
      const target = e.target;
      const prevSelection = this.yearsStepTarget.querySelector(".selected");
      if (prevSelection)
        prevSelection.classList.remove("selected");
      target.classList.add("selected");
      this.yearSelection = target.getAttribute("data-value") || target.value;
      if (!this.yearSelection)
        return;
      this.currentSelectionTargets.map((curr) => curr.innerText = this.yearSelection);
      this.populateMakes(this.yearSelection);
      this.changeStep("make");
    });
    __publicField(this, "handleMakeSelect", (e) => {
      e.preventDefault();
      const target = e.target;
      const prevSelection = this.makesStepTarget.querySelector(".selected");
      if (prevSelection)
        prevSelection.classList.remove("selected");
      target.classList.add("selected");
      this.makeSelection = target.getAttribute("data-value") || target.value;
      if (!this.makeSelection)
        return;
      this.currentSelectionTargets.map((curr) => curr.innerText = `${this.yearSelection} ${this.makeSelection}`);
      this.populateModels(this.makeSelection, this.yearSelection);
      this.changeStep("model");
    });
    __publicField(this, "handleModelSelect", (e) => {
      e.preventDefault();
      const target = e.target;
      const prevSelection = this.modelsStepTarget.querySelector(".selected");
      if (prevSelection)
        prevSelection.classList.remove("selected");
      target.classList.add("selected");
      this.modelSelection = target.getAttribute("data-value") || target.value;
      if (!this.modelSelection)
        return;
      const finalAnswer = `${this.yearSelection} ${this.makeSelection} ${this.modelSelection}`;
      this.currentSelectionTargets.map((curr) => curr.innerText = finalAnswer);
      this.submitButtonTarget.dataset.text = finalAnswer;
      this.saveDataToStore();
      this.handleQuestionSubmit(this.submitButtonTarget);
    });
    __publicField(this, "populateMakes", (year = "") => {
      const context = this;
      const url = `/api/v1/vehicles/makes?year=${year}&list_id=${this.listIdValue}`;
      this.clearVariants(this.makesSelectTarget, this.makesButtonsTarget);
      fetch(url).then(function(response) {
        return response.json();
      }).then(function(data) {
        if (data.errors || data.length === 0) {
          console.log("CARS API ERROR:populateMakes", data.errors);
        } else {
          context.createOptionsFromData(data, context.makesSelectTarget, context.makesButtonsTarget, "id", "name");
        }
      });
    });
    __publicField(this, "populateModels", (makesId, year = "") => {
      const context = this;
      const url = `/api/v1/vehicles/makes/${makesId}/models/?year=${year}&list_id=${this.listIdValue}`;
      this.clearVariants(this.modelsSelectTarget, this.modelsButtonsTarget);
      fetch(url).then(function(response) {
        return response.json();
      }).then(function(data) {
        if (data.errors || data.length === 0) {
          console.log("CARS API ERROR:populateModels", data.errors);
        } else {
          context.createOptionsFromData(data, context.modelsSelectTarget, context.modelsButtonsTarget, "id", "name");
        }
      });
    });
    __publicField(this, "changeStep", (step) => {
      this.currentStep = step;
      if (step === "year") {
        this.makesStepTarget.classList.add(...this.hiddenClasses);
        this.yearsStepTarget.classList.remove(...this.hiddenClasses);
      }
      if (step === "make") {
        this.yearsStepTarget.classList.add(...this.hiddenClasses);
        this.modelsStepTarget.classList.add(...this.hiddenClasses);
        this.makesStepTarget.classList.remove(...this.hiddenClasses);
      }
      if (step === "model") {
        this.makesStepTarget.classList.add(...this.hiddenClasses);
        this.modelsStepTarget.classList.remove(...this.hiddenClasses);
      }
    });
    __publicField(this, "handleBackButton", (e) => {
      e.preventDefault();
      const prevStep = this.currentStep === "model" ? "make" : "year";
      this.changeStep(prevStep);
    });
    __publicField(this, "createOptionsFromData", (data, selectEl, buttonsWrapper, valueParam, textParam) => {
      const MAX_BUTTONS = 12;
      const createOption = (item) => {
        let option = document.createElement("option");
        option.value = item[valueParam];
        option.text = item[textParam];
        selectEl.appendChild(option);
      };
      const createButton = (item) => {
        const element = document.createElement("li");
        element.className = 'border-white rounded border-solid border cursor-pointer inline-block font-bold text-sm p-3 bg-gray-light text-center uppercase transition md:text-xl md:px-3 md:py-5 relative after:content-[""] after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:bg-transparent';
        element.innerText = item[textParam];
        element.dataset.value = item[valueParam];
        element.addEventListener("click", (e) => {
          if (buttonsWrapper === this.makesButtonsTarget)
            this.handleMakeSelect(e);
          if (buttonsWrapper === this.modelsButtonsTarget)
            this.handleModelSelect(e);
        });
        buttonsWrapper.appendChild(element);
      };
      selectEl.classList.add("hidden");
      data.map(function(item, idx) {
        const currNumber = idx + 1;
        if (currNumber > MAX_BUTTONS) {
          selectEl.classList.remove("hidden");
          createOption(item);
        } else {
          createButton(item);
        }
      });
    });
    __publicField(this, "clearVariants", (selectEl, buttonsEl) => {
      const clearOptions = (selectEl2) => {
        const currentOptions = [...selectEl2.options];
        currentOptions.map((o) => {
          if (!o.classList.contains("select_placeholder"))
            o.remove();
        });
      };
      buttonsEl.innerHTML = "";
      clearOptions(selectEl);
    });
  }
  initialize() {
    console.log("Answer::CarButtonsController[index]", this.element);
    this.currentStep = "year";
    this.yearSelection;
    this.makeSelection;
    this.modelSelection;
  }
  connect() {
    console.log("Answer::CarButtonsController[connect]");
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "targets", [
  "yearsSelect",
  "makesSelect",
  "modelsSelect",
  "makesButtons",
  "modelsButtons",
  "yearsStep",
  "makesStep",
  "modelsStep",
  "currentSelection",
  "submitButton"
]);
__publicField(component_controller_default, "values", { listId: String });
__publicField(component_controller_default, "classes", ["hidden"]);
