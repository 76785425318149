var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
export default class custom_fb_ga_ef_to_sub_five_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  initialize() {
    console.log("custom_fb_ga_ef_to_sub_five snippet init", this.tokenValue);
  }
  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const s1val = urlParams.get("sub1");
    const s2val = urlParams.get("sub2");
    const s3val = urlParams.get("sub3");
    const s4val = urlParams.get("sub4");
    const s5val = urlParams.get("sub5");
    this.trackerModule.track("custom_ef_rendered_success", {
      offerId: this.offerIdValue,
      affiliateId: this.affiliateIdValue,
      sub1: this.rewriteParamWithThirdParty(this.subOneValue, s1val),
      sub2: this.rewriteParamWithThirdParty(this.subTwoValue, s2val),
      sub3: this.rewriteParamWithThirdParty(this.subThreeValue, s3val),
      sub4: this.rewriteParamWithThirdParty(this.subFourValue, s4val),
      sub5: this.rewriteParamWithThirdParty(this.subFiveValue, s5val),
      sub1_from_admin: this.subOneValue,
      sub2_from_admin: this.subTwoValue,
      sub3_from_admin: this.subThreeValue,
      sub4_from_admin: this.subFourValue,
      sub5_from_admin: this.subFiveValue,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  rewriteParamWithThirdParty(paramName, urlValue) {
    const token = typeof LeadiD != "undefined" && LeadiD.token;
    let gclId;
    let gaClientId;
    if (window.GclModule) {
      gclId = window.GclModule.getGclid();
      gaClientId = window.GclModule.getGaClientId();
    } else {
      console.warn("GclModule not found (rewriteParamWithThirdParty)");
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sVal = (key) => urlParams.get(key);
    console.log("rewriteParamWithThirdParty(paramName)", paramName);
    switch (paramName) {
      case "url":
        return urlValue;
      case "token":
        return token;
      case "gclid":
        return gclId;
      case "gaClientId":
        return gaClientId;
      default:
        return paramName;
    }
  }
}
__publicField(custom_fb_ga_ef_to_sub_five_controller_default, "values", {
  token: String,
  offerId: String,
  affiliateId: String,
  subOne: String,
  subTwo: String,
  subThree: String,
  subFour: String,
  subFive: String
});
