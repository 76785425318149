var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    this.isLoadingValue = true;
    this.tlyService = new TransparentlyService(this.campaignIdValue);
    this.publicErrorMessage = "Unfortunately, it looks like there are no offers available for you at this time.";
  }
  connect() {
    this.performWallLoading();
    if (this.tlyService.error) {
      this.errorMessageTarget.innerHTML = `<div class="p-5" data-error="no campaign id">${this.publicErrorMessage}</div>`;
    }
  }
  performWallLoading() {
    return __async(this, null, function* () {
      var _a;
      this.clearErrors();
      const resp = yield (_a = this.tlyService) == null ? void 0 : _a.loadWall();
      console.log("resp::", resp);
      this.isLoadingValue = false;
    });
  }
  isLoadingValueChanged() {
    return __async(this, null, function* () {
      if (this.isLoadingValue) {
        this.loaderTarget.classList.remove("hidden");
      } else {
        this.loaderTarget.classList.add("hidden");
      }
    });
  }
  clearErrors() {
    if (this.errorMessageTarget)
      this.errorMessageTarget.innerHTML = "";
  }
}
__publicField(component_controller_default, "values", {
  campaignId: String,
  isLoading: Boolean
});
__publicField(component_controller_default, "targets", ["result", "errorMessage", "clickableWrapper", "loader"]);
class TransparentlyService {
  constructor(campaignId) {
    __publicField(this, "getCurrentlyInsured", (storeAnswer) => {
      const insuredData = {
        "yes": "1",
        "no": "2"
      };
      return insuredData[storeAnswer.toLowerCase()];
    });
    __publicField(this, "getInsuranceProvider", (storeAnswer) => {
      let answerKey = false;
      const insuredData = {
        "progressive": "1",
        "geico": "2",
        "state farm": "3",
        "allstate": "4",
        "nationwide": "5",
        "farmers insurance": "6",
        "liberty mutual": "7",
        "elephant insurance": "8",
        "mercury insurance": "9",
        "safe auto": "10",
        "metlife": "11",
        "infinity insurance": "12",
        "dairyland insurance": "13",
        "the general": "14",
        "21st century": "15",
        "direct general": "16",
        "other": "17",
        "esurance": "18",
        "travelers": "19",
        "usaa": "20"
      };
      if (storeAnswer) {
        answerKey = Object.keys(insuredData).find((name) => storeAnswer.toLowerCase().includes(name));
      }
      return insuredData[answerKey] || insuredData["other"];
    });
    __publicField(this, "getGender", (storeAnswer) => {
      const genderData = {
        "male": "1",
        "female": "2",
        "not": "3"
      };
      return genderData[storeAnswer.toLowerCase()] || "3";
    });
    __publicField(this, "getMartialStatus", (storeAnswer) => {
      const martialData = {
        "no": "1",
        "yes": "2",
        "divorced": "3"
      };
      return martialData[storeAnswer.toLowerCase()];
    });
    __publicField(this, "getHomeOwner", (storeAnswer) => {
      const homeOwnerData = {
        "yes": "1",
        "no": "2",
        "other": "3"
      };
      return homeOwnerData[storeAnswer.toLowerCase()];
    });
    __publicField(this, "getDob", (storeAnswer) => {
      const parseDate = (input, format) => {
        format = format || "dd-mm-yyyy";
        var parts = input.match(/(\d+)/g), i = 0, fmt = {};
        format.replace(
          /(yyyy|dd|mm)/g,
          function(part) {
            fmt[part] = i++;
          }
        );
        return new Date(parts[fmt["yyyy"]], parts[fmt["mm"]] - 1, parts[fmt["dd"]], 12);
      };
      return parseDate(storeAnswer).toISOString().split("T")[0];
    });
    __publicField(this, "getThreeYearIncidentCount", (storeAnswer) => {
      const incidentData = {
        "no": "1",
        "yes": "2",
        "0": "1",
        "1": "2",
        "2": "3",
        "3": "4",
        "3+": "4"
      };
      return incidentData[storeAnswer.toLowerCase()];
    });
    __publicField(this, "getDui", (storeAnswer) => {
      const duiData = {
        "yes": "1",
        "no": "2"
      };
      return duiData[storeAnswer.toLowerCase()];
    });
    __publicField(this, "getDeviceType", () => {
      const ua = navigator.userAgent;
      const deviceTypeData = {
        "desktop": "1",
        "mobile": "2",
        "tablet": "3"
      };
      let type = "desktop";
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        type = "tablet";
      }
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )) {
        type = "mobile";
      }
      return deviceTypeData[type];
    });
    this.campaignId = campaignId;
    console.log("this.campaignId::", this.campaignId);
    this.error = !this.campaignId;
  }
  loadWall() {
    return __async(this, null, function* () {
      this.locationData = JSON.parse(document.body.dataset.sessionLocation || {});
      this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}");
      this.questionData = JSON.parse(
        localStorage.getItem("questions_results") || "{}"
      );
      this.params = new URLSearchParams(window.location.search);
      const data = {
        "state": this.locationData.region_code,
        "pubcampaignid": this.campaignId,
        "vertical": "2",
        "zipcode": this.locationData.zip,
        "vehiclecount": "1",
        "vehicleYear": this.questionData["carYear"],
        "make": this.questionData["carMake"],
        "model": this.questionData["carModel"],
        "currentlyinsured": this.getCurrentlyInsured(this.questionData["currentlyinsured"]),
        "currentInsuranceProvider": this.getInsuranceProvider(this.questionData["currentInsuranceProvider"]),
        "gender": this.getGender(this.questionData["gender"]),
        "dob": this.getDob(this.questionData["birth"]),
        "maritalStatus": this.getMartialStatus(this.questionData["maritalStatus"]),
        "threeyearincidentcount": this.getThreeYearIncidentCount(this.questionData["threeyearincidentcount"]),
        "homeowner": this.getHomeOwner(this.questionData["homeowner"]),
        "ms1": this.params.get("sub1"),
        "ms2": this.params.get("sub4"),
        "FullName": `${this.userData.first_name} ${this.userData.last_name}`,
        "firstName": this.userData.first_name,
        "lastName": this.userData.last_name,
        "email": this.userData.email,
        "phone": this.userData.phone,
        "ConsumerIp": this.locationData.ip,
        "ConsumerUserAgent": navigator.userAgent,
        "devicetype": this.getDeviceType()
      };
      console.log("data::", data);
      return Transparently.Render(data);
    });
  }
}
