var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  get loaderController() {
    return this.application.getControllerForElementAndIdentifier(this.parentLoader, "shared--loader--text-component");
  }
  initialize() {
    this.DEFAULT_DELAY = 1800;
    this.parentLoader = this.element.closest(".loader");
    console.log("[Shared::Loader::TextItem::Component::initialize]", this.element);
    this.showElement();
    setTimeout(() => {
      this.hideElement();
    }, this.delayValue);
  }
  connect() {
    console.log("[Shared::Loader::TextItem::Component::connect]", this.delayValue);
  }
  showElement() {
    const fadeInClass = "animate-in fade-in zoom-in duration-1000 ease-out";
    this.element.classList.add(...fadeInClass.split(" "));
    setTimeout(() => {
      this.element.classList.remove("opacity-0");
      this.element.classList.remove("hidden");
    }, 100);
  }
  hideElement() {
    const fadeInClass = "animate-in fade-in";
    const fadeOutClass = "animate-out fade-out";
    this.element.classList.remove(...fadeInClass.split(" "));
    this.element.classList.add(...fadeOutClass.split(" "));
    setTimeout(() => {
      this.element.classList.add("hidden");
      this.loaderController.nextItem();
    }, 1e3);
  }
  disconnect() {
    console.log("[Shared::Loader::TextItem::Component::disconnect]", this.element);
  }
}
__publicField(component_controller_default, "values", {
  delay: Number
});
