var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import { LinkModule } from "@/modules/link_module";
import { ResultsStoreModule } from "@/modules/results_store_module";
import ahoy from "ahoy.js";
import SparkMD5 from "spark-md5";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    this.newTabRedirect;
    this.redirect;
    this.text;
    this.answerId;
    this.questionTrackingData;
    this.parentQuestion = this.element.closest(".lander-item");
  }
  connect() {
  }
  disconnect() {
  }
  handleAnswer() {
    return __async(this, null, function* () {
      yield this.trackAnswer();
      this.questionController.triggerModal();
      this.handleRedirect();
      if (!this.redirect)
        this.chainController.nextStep();
    });
  }
  getAnswerData(answer) {
    console.log("getAnswerData answer", answer);
    const answerData = answer.dataset;
    const domain = window.location.host;
    this.questionTrackingData = this.questionController.trackingData();
    this.newTabRedirect = answerData == null ? void 0 : answerData.newTabRedirect;
    this.redirect = answerData == null ? void 0 : answerData.redirect;
    this.text = answerData == null ? void 0 : answerData.text;
    this.id = answerData == null ? void 0 : answerData.id;
    const uniqSeed = this.text || this.id;
    this.trackingAnswerId = SparkMD5.hash(this.questionTrackingData.tracking_question_id + uniqSeed + domain);
  }
  handleRedirect() {
    const newTabRedirect = this.newTabRedirect === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.newTabRedirect;
    const redirect = this.redirect === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.redirect;
    if (newTabRedirect)
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(newTabRedirect),
        true
      );
    if (redirect)
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(redirect)
      );
  }
  trackAnswer() {
    return __async(this, null, function* () {
      this.resultsStoreModule = yield ResultsStoreModule();
      yield this.resultsStoreModule.init();
      yield this.resultsStoreModule.saveAnswer(this.questionIdValue, this.text);
      console.log("THIS.SERVICENAME_VALUE::", this.questionServicenameValue);
      if (this.questionServicenameValue)
        yield this.resultsStoreModule.saveAnswer(this.questionServicenameValue, this.text);
      this.trackerModule.track("answer_click", __spreadProps(__spreadValues({}, this.questionTrackingData), {
        tracking_answer_id: this.trackingAnswerId,
        tracking_answer: this.text,
        question_id: this.questionIdValue,
        question_chain_id: this.questionChainIdValue,
        answer_id: this.id,
        answer_text: this.text,
        page: window.location.pathname,
        url: window.location.href
      }));
    });
  }
  get chainController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(".chain"),
      "chain--component"
    );
  }
  get questionController() {
    return this.application.getControllerForElementAndIdentifier(
      this.parentQuestion,
      "chain--question--component"
    );
  }
}
__publicField(component_controller_default, "values", { questionId: String, questionChainId: String, questionServicename: String });
