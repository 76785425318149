var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export class LeaptheoryModule {
  constructor() {
    this.submitLeadURL = `${window.location.origin}/api/v1/leaptheory/send_lead`;
    this.internalLeadURL = `${window.location.origin}/leads`;
  }
  sendLead() {
    return __async(this, null, function* () {
      this.locationData = JSON.parse(document.body.dataset.sessionLocation || {});
      this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}");
      this.questionData = JSON.parse(
        localStorage.getItem("questions_results") || "{}"
      );
      const data = {
        leaptheory: {
          client_ip: this.locationData.ip,
          referer: window.location.href,
          email: this.userData.email,
          first_name: this.userData.first_name,
          last_name: this.userData.last_name,
          phone: this.userData.phone,
          home_phone: this.questionData.home_phone,
          work_phone: this.questionData.work_phone,
          zipcode: this.questionData.zip,
          address: this.questionData.address,
          city: this.userData.city,
          state: this.userData.state,
          date_of_birth: this.questionData.date_of_birth,
          ssn: this.questionData.social,
          loan_amount: this.questionData.loan_amount,
          loan_purpose: this.questionData.loan_purpose,
          credit_score: this.questionData.credit_rating,
          months_at_address: this.questionData.months_at_address,
          home_ownership: this.questionData.home_ownership,
          drivers_license: this.questionData.drivers_license,
          drivers_license_state: this.questionData.drivers_license_state,
          employment_type: this.questionData.employment_status,
          employer_name: this.questionData.employer_name,
          military: this.questionData.military,
          months_employed: this.questionData.months_employed,
          monthly_income: this.questionData.monthly_income,
          pay_frequency: this.questionData.employment_pay_frequency,
          next_pay_date: this.questionData.next_pay_date,
          second_pay_date: this.questionData.second_pay_date,
          pay_type: this.questionData.pay_type,
          bank_routing_number: this.questionData.bank_routing_number,
          bank_name: this.questionData.bank_name,
          bank_account_number: this.questionData.bank_account_number,
          bank_account_type: this.questionData.bank_account_type,
          months_at_bank: this.questionData.months_at_bank
        }
      };
      const response = yield fetch(this.submitLeadURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(function(res) {
        return res;
      });
      console.log("RESPONSE::", response);
      let result = yield response.json();
      console.log("RESULT::", result);
      result = result.data || result.errors || {};
      if (response.status === 200) {
        if (result.status === "error") {
          this.renderError(result.messages);
        } else {
          const internalLeadRes = yield this.saveExternalId(result.leadID);
          console.log("internalLeadRes::", internalLeadRes);
          if (result.redirectURL)
            window.location.href = result.redirectURL;
        }
        return { result };
      } else {
        console.log("ERR RESPONSE::", response);
        return {
          error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
          errors: result["errors"]
        };
      }
    });
  }
  saveExternalId(externalId) {
    return __async(this, null, function* () {
      const response = yield fetch(this.internalLeadURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          lead: {
            external_storage_token: externalId
          }
        })
      }).then(function(res) {
        return res;
      });
      let result = yield response.json();
      result = result.data || result.errors || {};
      console.log("saveExternalId:RESULT::", result);
      if (response.status === 200) {
        return { result };
      } else {
        console.log("saveExternalId:ERR RESPONSE::" + externalId, response);
        return {
          error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
          errors: result["errors"]
        };
      }
    });
  }
  renderError(messages) {
    console.log("Leaptheory errors", messages);
    const messageItems = messages.map((message) => {
      return `<li>${message}</li>`;
    });
    const errorTemplate = `
    <div class="w-full text-white bg-red-500 error-box fixed top-0 left-0">
        <div class="container flex items-center justify-between flex-wrap px-6 py-4 mx-auto">
            <div class="flex w-full">
              <svg viewBox="0 0 40 40" class="w-6 h-6 fill-current">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"></path>
              </svg>
              <p class="mx-3">Sorry, something went wrong.</p>
            </div>
            <ul class="w-full pl-12 mt-2 list-disc">
              ${messageItems.join("")}
            </ul>
        </div>
    </div>
    `;
    console.log("Leaptheory errorsTemp", errorTemplate);
    document.body.insertAdjacentHTML("beforeend", errorTemplate);
  }
}
