var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ahoy from "ahoy.js";
import { ApplicationController as ApplicationControllerStimulusUse, useApplication } from "stimulus-use";
export default class extends ApplicationControllerStimulusUse {
  constructor() {
    super(...arguments);
    __publicField(this, "handleError", (error) => {
      const context = {
        controller: this.identifier,
        user_id: this.userId
      };
      console.log("handleError", context, error);
    });
  }
  initialize() {
  }
  connect() {
    useApplication(this);
    console.log("[ApplicationController connect]");
  }
}
