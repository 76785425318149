var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("Answer::Checkbox::Question::Controller[index]", this.element);
    this.currentSelection = this.element.querySelector("input[checked]");
    this.parentQuestion = this.element.closest('[data-controller="chain--answer--checkbox--component chain--answer--component"]');
    this.haveSiblings = this.parentQuestion.querySelectorAll('[data-chain--answer--checkbox--component-target="question"]').length > 1;
  }
  connect() {
    console.log("Answer::Checkbox::Question::Controller[connect]");
  }
  selectCheckbox(event) {
    const target = event.target;
    this.currentSelection = target;
    this.checkmarkTargets.map((t) => {
      t.classList.add(this.hiddenClass);
    });
    const parentEl = target.closest("label");
    console.log("paretnEl", parentEl);
    const checkmarkTarget = parentEl.querySelector("svg");
    console.log("checkmarkTarget", checkmarkTarget);
    checkmarkTarget.classList.remove(this.hiddenClass);
    if (!this.haveSiblings)
      this.parentQuestionController.handleSubmit();
  }
  handleAnswer() {
    return __async(this, null, function* () {
      console.log("Answer::Checkbox::Question::Controller", this.currentSelection);
      this.answerController.getAnswerData(this.currentSelection);
      return yield this.answerController.trackAnswer();
    });
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
  get parentQuestionController() {
    return this.application.getControllerForElementAndIdentifier(this.parentQuestion, "chain--answer--checkbox--component");
  }
}
__publicField(component_controller_default, "targets", ["option", "checkmark"]);
__publicField(component_controller_default, "classes", ["hidden"]);
