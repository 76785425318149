import ApplicationController from "../../../../javascript/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
    console.log("button_component base_component initialize");
  }
  connect() {
    console.log("button_component base_component connect");
  }
  log() {
    console.log("button_component base_component here");
  }
}
