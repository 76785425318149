var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    this.nextinsureService = new NextinsureService("");
    this.publicErrorMessage = "Unfortunately, it looks like there are no offers available for you at this time.";
  }
  connect() {
    this.performWallLoading();
  }
  performWallLoading() {
    return __async(this, null, function* () {
      var _a;
      this.isLoadingValue = true;
      this.clearErrors();
      const resp = yield (_a = this.nextinsureService) == null ? void 0 : _a.loadWall();
      console.log("respppp", resp);
      yield this.renderWall(resp);
      this.isLoadingValue = false;
    });
  }
  isLoadingValueChanged() {
    return __async(this, null, function* () {
      if (this.isLoadingValue) {
        this.loaderTarget.classList.remove("hidden");
      } else {
        this.loaderTarget.classList.add("hidden");
      }
    });
  }
  clearErrors() {
    if (this.errorMessageTarget)
      this.errorMessageTarget.innerHTML = "";
  }
  renderWall(api_response) {
    return __async(this, null, function* () {
      const wall = api_response;
      console.log("WALL", wall);
      const renderErrors = (resp) => {
        console.log("resprenderERR", resp.errors);
        const errors = resp.errors || "[]";
        let message = "";
        errors.map((error) => {
          message += `<div class="p-2 text-xs">${error.message}</div>`;
        });
        return message;
      };
      if (wall["error_message"]) {
        this.errorMessageTarget.innerHTML = `<div class="p-5" data-error="${wall["error_message"]}">${this.publicErrorMessage}</div>`;
        this.errorMessageTarget.innerHTML += renderErrors(wall);
      } else {
        const offers = wall["result"]["listing"] || [];
        console.log("OFFERS", offers);
        const offerListResponse = yield fetch("/uikit/offer_list_markup", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": getMetaValue("csrf-token")
          },
          body: JSON.stringify({
            offers,
            wall_source: "nextinsure"
          })
        });
        const offerList = yield offerListResponse.json();
        if (this.resultTarget) {
          this.resultTarget.innerHTML += offerList["result"];
        }
      }
    });
  }
}
__publicField(component_controller_default, "values", {
  trialId: String,
  redirectUrl: String,
  externalId: String,
  isLoading: Boolean
});
__publicField(component_controller_default, "targets", ["result", "errorMessage", "clickableWrapper", "loader"]);
function getMetaValue(name) {
  var _a;
  const element = (_a = document == null ? void 0 : document.head) == null ? void 0 : _a.querySelector(`meta[name="${name}"]`);
  return element == null ? void 0 : element.getAttribute("content");
}
class NextinsureService {
  constructor(token = "") {
    this.submitLeadURL = `${window.location.origin}/api/v1/nextinsure/send_lead`;
    this.internalLeadURL = `${window.location.origin}/leads`;
  }
  loadWall() {
    return __async(this, null, function* () {
      this.locationData = JSON.parse(document.body.dataset.sessionLocation || {});
      this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}");
      this.questionData = JSON.parse(
        localStorage.getItem("questions_results") || "{}"
      );
      const response = yield fetch(this.submitLeadURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          nextinsure: {
            client_id: "668893",
            client_ip: this.locationData.ip,
            user_agent: navigator.userAgent,
            referrer: "path.seniorbenefitsworth.com",
            zipcode: this.questionData.zip
          }
        })
      }).then(function(res) {
        return res;
      });
      console.log("RESPONSE::", response);
      let result = yield response.json();
      console.log("RESULT::", result);
      result = result.data || result.errors || {};
      if (response.status === 200) {
        const internalLeadRes = this.saveExternalId(result["searchid"]);
        console.log("internalLeadRes::", internalLeadRes);
        return { result };
      } else {
        console.log("ERR RESPONSE::", response);
        return {
          error_message: `Status: ${response.status} - Message: ${JSON.stringify(result["errors"])}`,
          errors: result["errors"]
        };
      }
    });
  }
  saveExternalId(externalId) {
    return __async(this, null, function* () {
      const response = yield fetch(this.internalLeadURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          lead: {
            external_storage_token: externalId
          }
        })
      }).then(function(res) {
        return res;
      });
      let result = yield response.json();
      result = result.data || result.errors || {};
      console.log("saveExternalId:RESULT::", result);
      if (response.status === 200) {
        return { result };
      } else {
        console.log("saveExternalId:ERR RESPONSE::" + externalId, response);
        return {
          error_message: `Status: ${response.status} - Message: ${JSON.stringify(result["errors"])}`,
          errors: result["errors"]
        };
      }
    });
  }
}
