var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    console.log("[offer_list_component::initialize]");
  }
  connect() {
    this.stepValue = 1;
    this.stepIndexValue = 0;
    console.log("[offer_list_component::connect]");
  }
  nextOffer(e) {
    console.log("NEXt_offer");
    if (this.stepValue >= this.cardTargets.length) {
      this.performFinalRedirect();
    } else {
      this.stepValue++;
      this.stepIndexValue++;
    }
  }
  stepValueChanged() {
    console.log("this.cardTargets", this.cardTargets);
    const prevItem = this.cardTargets[this.stepIndexValue - 1];
    const newItem = this.cardTargets[this.stepIndexValue];
    if (newItem) {
      newItem.classList.remove("w-0", "h-0", "overflow-hidden");
      newItem.classList.add("opacity-100");
      if (prevItem)
        prevItem.classList.add("w-0", "h-0", "overflow-hidden");
      if (prevItem)
        prevItem.classList.remove("opacity-100");
    }
  }
  performFinalRedirect() {
    return __async(this, null, function* () {
      const formUrlParams = (obj) => "?" + Object.keys(obj).map((key) => {
        return obj[key] ? key + "=" + obj[key] : "";
      }).join("&");
      let finalUrl = window.localStorage.getItem("savedRedirect");
      if (finalUrl) {
        this.userStoreModule = yield ResultsStoreModule("savedUser");
        const userStoreObject = yield this.userStoreModule.getStore("savedUser");
        finalUrl = this.linkModule.addCurrentParamsToUrl(finalUrl);
        finalUrl = new URL(this.linkModule.addParamsToUrl(finalUrl)(formUrlParams(userStoreObject)));
        if (finalUrl.origin == window.location.origin) {
          this.linkModule.performRedirect(finalUrl.toString());
        } else {
          this.linkModule.performRedirect(finalUrl.toString());
        }
      }
    });
  }
}
__publicField(component_controller_default, "values", {
  step: Number,
  stepIndex: Number
});
__publicField(component_controller_default, "targets", ["card"]);
