var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "saveDataToStore", (text) => __async(this, null, function* () {
      this.resultsStoreModule = yield ResultsStoreModule();
      yield this.resultsStoreModule.init();
      yield this.resultsStoreModule.saveAnswer("insurance", text);
    }));
    __publicField(this, "handleQuestionSubmit", (target) => {
      this.answerController.getAnswerData(target);
      this.answerController.handleAnswer();
    });
    __publicField(this, "handleInsuranceSelect", (e) => {
      e.preventDefault();
      const target = e.target;
      target.classList.add("selected");
      const currentSelection = target.getAttribute("data-text") || target.value;
      console.log("THIS CURRENT SELECTION", currentSelection);
      this.saveDataToStore(currentSelection);
      this.handleQuestionSubmit(target);
    });
  }
  initialize() {
    console.log("Answer::InsuranceButtonsController[index]", this.element);
  }
  connect() {
    console.log("Answer::InsuranceButtonsController[connect]");
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
