var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "handlePrediction", (predictions, status) => __async(this, null, function* () {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        console.error("GOOGLE PLACES SERVICE", status);
        this.validatorController.addErrorState();
        return;
      }
      if (predictions.length > 0) {
        const firstPrediction = predictions[0];
        const placeId = firstPrediction.place_id;
        yield this.getStateByPlaceId(placeId);
        this.refreshSessionToken();
      }
    }));
    __publicField(this, "getStateByPlaceId", (placeId) => __async(this, null, function* () {
      const saveToUserStore = (key, text) => __async(this, null, function* () {
        this.userStoreModule = yield ResultsStoreModule("savedUser");
        yield this.userStoreModule.init();
        yield this.userStoreModule.saveAnswer(key, text);
      });
      const request = {
        placeId,
        fields: ["address_component"]
      };
      const saveStateToUserStore = (place, _status) => __async(this, null, function* () {
        console.log("[LeadForms::Field::Zipcode::Component::getStateByPlaceId]", place);
        const stateData = place["address_components"].find((i) => i.types.includes("administrative_area_level_1"));
        const cityData = place["address_components"].find((i) => i.types.includes("locality"));
        const shortState = stateData["short_name"];
        const city = cityData["long_name"];
        saveToUserStore("state", shortState);
        saveToUserStore("city", city);
        this.refreshAgencies();
        return stateData;
      });
      yield this.detailsService.getDetails(request, saveStateToUserStore);
    }));
    __publicField(this, "refreshAgencies", () => __async(this, null, function* () {
      const agenciesElements = [
        ...document.querySelectorAll(
          '[data-controller="chain--answer--solar-buttons--component chain--answer--component"]'
        )
      ];
      agenciesElements.map((el) => {
        const controller = this.application.getControllerForElementAndIdentifier(
          el,
          "chain--answer--solar-buttons--component"
        );
        controller.populateByState();
      });
    }));
    __publicField(this, "refreshSessionToken", () => {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    });
  }
  get validatorController() {
    return this.application.getControllerForElementAndIdentifier(this.inputTarget, "field-validation");
  }
  initialize() {
    this.service = new google.maps.places.AutocompleteService();
    this.detailsService = new google.maps.places.PlacesService(this.mapTarget);
    this.sessionToken = false;
    console.log("[LeadForms::Field::Zipcode::Component::initialize]", this.detailsService);
  }
  connect() {
  }
}
__publicField(component_controller_default, "targets", ["input", "map"]);
